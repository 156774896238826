import { Component } from '@angular/core';
import { AuthenticationService }  from './services/core/authentication.service';
import { ConfigService }  from './services/core/config.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConnectionService }  from './services/core/api-connection.service';
import { UsersService }  from './services/users.service';
import { TitleTagService }  from './services/core/title-tag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '';
  pageTitle = 'Schoolbeheer';
  showModal: boolean;
  submitted = false;
  unreadFeedback = 0;

  SYSTEM_NAME = this.configService.get('title');
  urlParameters = {};
  
  public currentUser : any;
  
  constructor(
	public api: ApiConnectionService,
	public configService: ConfigService,
	public usersService: UsersService,
    private router: Router,
  	private authenticationService: AuthenticationService, 
  	private ngxLoader: NgxUiLoaderService,
	private titleTagService: TitleTagService,
  ) {
  	let self = this;

	this.title = 'portal-' + configService.get('system_key') +'-nl';

	this.checkToken();

	self.checkHashArguments().then(function () {
		self.setMetaTags();
	});

    this.ngxLoader.start();
  	this.authenticationService.getCurrentUser().then(function (data) {
		
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
		
		self.authenticationService.authState.subscribe(data => {
			if (data === false) {
				self.router.navigate(['login']);
			}
		});
		
		self.ngxLoader.stop();		
		
  	}).catch (function (data) {
		self.router.navigate(['login']);
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
		
		self.authenticationService.authState.subscribe(data => {
			if (data === false) {
				self.router.navigate(['login']);
			}
		});

		
  		self.ngxLoader.stop();
  	});
	
	self.usersService.getUnreadCount();
	self.usersService.data.subscribe(data => {
		if(typeof(data) === 'number') {
			self.unreadFeedback = data;
		} else {
			self.unreadFeedback = 0;
		}
	});
  }

  checkToken () {
	let self = this;
	return new Promise(function(resolve, reject) 
	{
		try {
			let search = location.hash.substring(1);
			let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

			if (params['loginToken']) {
				self.loginWithHash(params['loginToken']).then(function () {
					resolve(true);
				}).catch(function (error) {
				});
			} else {
				resolve(true);
			}
		} catch (e) {
			resolve(true);
		}
	});
  }
    
  async loginWithHash (hash) {
	let self = this;
  	return <any>this.api.read('users/loginByToken/'+hash+'').then(function (data) {
		self.api.setAccessToken(data['accessToken']);
		self.api.setRefreshToken(data['refreshToken']);
		
  	}).catch(function (data) {
		window.location.hash = '';
		window.location.reload();  		
  	});	  
  }
  
  logout () {
  	this.authenticationService.logout();
  }

  async checkHashArguments () {
	try {
		let search = location.hash.substring(1);
		let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

		this.urlParameters = params;
	} catch (e) {

	}
}

  setMetaTags () {
	this.titleTagService.setSocialMediaTags(this.configService.get('front_url'), this.configService.get('system_name'), this.configService.get('page_description'), this.configService.get('intro_image_url') );
  }
}
